import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import v from "../styles/vars"
import breakpoints from "../styles/breakpoints"
import clearfix from "../styles/clearfix"
import { lighten, transparentize } from "polished"

const MenuNavigationContainer = styled.div`
  background-color: ${v.navBackground};

  ${breakpoints.upHorizontalNavigation} {
    margin: 0 auto;
    max-width: ${v.pageWidth};
  }

  ${breakpoints.downHorizontalNavigation} {
    &.disableMobile {
      display: none;
    }
  }
`

const MenuNavigation = styled.ul`
  ${clearfix}
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
`

const BasicMenuItem = styled.li`
  display: block;
  margin-bottom: 0;

  a,
  a:visited {
    display: block;
    text-decoration: none;
    padding: 16px 20px;
    color: ${v.navText};

    &:hover,
    &:focus,
    &:active {
      background-color: ${lighten(0.1, v.navBackground)};
      color: ${v.navText};
    }
  }

  a.active {
    background-color: ${lighten(0.2, v.navBackground)};
  }
`

const ResponsiveMenuItem = styled(BasicMenuItem)`
  ${breakpoints.upHorizontalNavigation} {
    float: left;

    a {
      padding: 10px;

      &:hover,
      &:focus,
      &:active {
        box-shadow: inset 0 -5px 2px -2px ${transparentize(1 - 0.25, "#000")};
      }

      &.active {
        position: relative;
        z-index: 1;

        &,
        &:hover,
        &:focus,
        &:active {
          background-color: ${v.backgroundColor};
          box-shadow: 5px 0 2px -2px ${transparentize(1 - 0.25, "#000")};
        }
      }
    }
  }
`

const Navigation = ({ variant = "basic", disableMobile = false }) => {
  const MenuItem = variant === "responsive" ? ResponsiveMenuItem : BasicMenuItem

  return (
    <StaticQuery
      query={graphql`
        query SiteNavigationQuery {
          site {
            siteMetadata {
              menuLinks {
                name
                link
                label
              }
            }
          }
        }
      `}
      render={data => (
        <MenuNavigationContainer className={disableMobile && "disableMobile"}>
          <MenuNavigation>
            {data.site.siteMetadata.menuLinks.map(({ name, link, label }) => (
              <MenuItem key={name}>
                <Link
                  to={link}
                  activeClassName="active"
                  partiallyActive={link !== "/"}
                >
                  {label}
                </Link>
              </MenuItem>
            ))}
            {/* <?php wp_nav_menu(array('theme_location' => 'primary', 'depth' => 1)); ?> */}
          </MenuNavigation>
        </MenuNavigationContainer>
      )}
    />
  )
}
Navigation.propTypes = {
  variant: PropTypes.oneOf(["basic", "responsive"]),
  disableMobile: PropTypes.bool,
}

export default Navigation
