import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import Header from "./header"
import Sidebar from "./sidebar"
import "./layout.scss"
import v from "../styles/vars"
import { PageWide } from "./page-wide"
import { px } from "../styles/units"
import breakpoints from "../styles/breakpoints"
import useScriptedClient from "../utils/useScriptedClient"
import MobileMenu from "./mobile-menu"
import Navigation from "./navigation"
import { useCreatorPlugins } from "../creatorPlugins"
import CloseIcon from "../utils/CloseIcon"

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${v.backgroundColor};
  }
`

const SiteMain = styled(PageWide)`
  position: relative;
  margin-top: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    margin-top: ${px(v.mobileGap)};
  }

  ${breakpoints.upBreakpointSidebarColumn} {
    display: flex;
  }
`

const SiteContent = styled.div`
  margin-bottom: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    margin-bottom: ${px(v.mobileGap)};
  }

  ${breakpoints.upBreakpointSidebarColumn} {
    flex: 1;
    min-width: 0;
  }
`

const SiteSide = styled.div`
  ${breakpoints.upBreakpointSidebarColumn} {
    flex: 0 ${px(v.sidebarAreaWidth)};
    margin-left: ${px(v.sidebarGap)};
  }
`

const Layout = ({ children, variant }) => {
  const isScriptedClient = useScriptedClient()
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = useCallback(() => setMenuOpen(open => !open), [])
  useCreatorPlugins()

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleDescriptionQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <div id="document-root">
          {isScriptedClient && (
            <MobileMenu
              isOpen={menuOpen}
              id="site-side-navigation-menu"
              outerContainerId="document-root"
              pageWrapId="page-container"
              customBurgerIcon={false}
              customCrossIcon={<CloseIcon />}
              onClose={() => setMenuOpen(false)}
            >
              <Navigation variant="basic" />
            </MobileMenu>
          )}
          <GlobalStyles />
          <div id="page-container">
            <Header
              siteTitle={data.site.siteMetadata.title}
              siteDescription={data.site.siteMetadata.description}
              variant={variant}
              toggleMenu={isScriptedClient ? toggleMenu : undefined}
            />
            <SiteMain>
              <SiteContent>{children}</SiteContent>
              <SiteSide>
                <Sidebar />
              </SiteSide>
            </SiteMain>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
}

export default Layout
