import React from "react"
import styled from "styled-components"
import { FaBars } from "react-icons/fa"

const MenuContainer = styled.div`
  padding: 5px;
`

const MenuIcon = () => (
  <MenuContainer>
    <FaBars color="#fff" size="32px" />
  </MenuContainer>
)

export default MenuIcon
