import styled from "styled-components"
import v from "../styles/vars"
import breakpoints from "../styles/breakpoints"
import { px } from "../styles/units"

export const PageWide = styled.div`
  max-width: ${px(v.pageWidth + v.pagePadding * 2)};
  margin: 0 auto;
  padding: 0 ${px(v.pagePadding)};
  box-sizing: border-box;

  ${breakpoints.downHorizontalNavigation} {
    padding: 0 15px;
  }
`

export const BrandingPageWide = styled(PageWide)`
  ${breakpoints.downHorizontalNavigation} {
    max-width: auto;
    margin: 0;
    padding: 0;
  }
`
