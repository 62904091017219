import styled from "styled-components"
import v from "../styles/vars"

export const PrimaryButton = styled.div`
	&,
	&:visited {
		display: inline-block;
		padding: 10px;
		background: ${v.backgroundColor};
		border-radius: 1px;
		font-size: 1em;
		font-weight: inherit;
		text-decoration: none;
		color: #fff;
		border: none;
		cursor: pointer;
	}
}`
