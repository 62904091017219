import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import FacebookPageWidget from "./widgets/facebook-page-widget"
import RecentPostsWidget from "./widgets/recent-posts-widget"
import { px } from "../styles/units"
import breakpoints from "../styles/breakpoints"
import v from "../styles/vars"

const Secondary = styled.div`
  display: grid;
  grid-gap: ${px(v.gap)};
  grid-template-columns: 1fr;
  margin-bottom: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    grid-gap: ${px(v.mobileGap)};
    margin-bottom: ${px(v.mobileGap)};
  }

  ${breakpoints.downBreakpointSidebarColumn} {
    @media (min-width: 720px) {
      grid-template-columns: auto 1fr;
    }
  }
`

const LinkFooter = styled.div`
  color: #fff;
  margin-top: ${px(-v.gap / 2)};

  ${breakpoints.downHorizontalNavigation} {
    margin-top: ${px(-v.mobileGap / 2)};
  }

  a,
  a:visited {
    color: #fff;
    border-bottom: 1px dotted #fff;
    text-decoration: none !important;

    &:hover,
    &:active,
    &:focus {
      color: #ccc;
    }
  }
`

const Sidebar = () => (
  <Secondary id="secondary" role="complementary">
    <FacebookPageWidget />
    <RecentPostsWidget />
    <LinkFooter>
      <Link to="/privacy/">{"Privacy & Cookies"}</Link>
    </LinkFooter>
  </Secondary>
)

export default Sidebar
