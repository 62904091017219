import React from "react"
import styled from "styled-components"
import Card, { CardRoot } from "./card"

export const WidgetRoot = styled(CardRoot)`
  ul {
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Widget = props => <Card as="aside" root={WidgetRoot} {...props} />

export default Widget
