import invariant from "invariant"
import { upperFirst } from "lodash"
import styled from "styled-components"
import { math } from "polished"
import v from "../styles/vars"

const levelSizes = {
  h1: "beta",
  h2: "delta",
  h3: "epsilon",
  h4: "zeta",
  h5: "zeta",
  h6: "zeta",
}

/**
 * Generates a header component (a h# styled component with base styles)
 */
export default function header(level, component) {
  invariant(level >= 1 && level <= 6, "level must be between 1-6")
  const size = levelSizes["h" + level]
  const fontSize = v["text" + upperFirst(size)]
  component = component || "h" + level

  let h = styled(component)`
    /* @todo Give headers a blue-ish color instead? */
    color: #222;
    font-size: ${fontSize};
    margin-top: ${math(`(${v.lineHeight} * 2) / ${fontSize}`)};
    margin-bottom: 0;
    font-weight: ${level <= 3 ? v.fontWeight : "bold"};

    & + p,
    & + img + p {
      margin-top: 0;
    }
  `

  if (level >= 3) {
    h = styled(h)`
      font-family: inherit;
    `
  }

  return h
}
