import { useState, useEffect } from "react"

/**
 * Returns a boolean indicating whether the component is being rendered
 * client-side and the client has JS enabled.
 */
export default function useScriptedClient() {
  const [isScriptedClient, setIsScriptedClient] = useState(false)
  useEffect(() => {
    setIsScriptedClient(true)
  }, [])

  return isScriptedClient
}
