import v from "./vars"
import { px } from "./units"

const up = val => `@media (min-width: ${px(val)})`
const down = val => `@media (max-width: ${px(val - 1)})`

const breakpoints = {
  upHorizontalNavigation: up(v.breakpointHorizontalNavigation),
  downHorizontalNavigation: down(v.breakpointHorizontalNavigation),
  upBreakpointGridColumns: up(v.breakpointGridColumns),
  downBreakpointGridColumns: down(v.breakpointGridColumns),
  upBreakpointSidebarColumn: up(v.breakpointSidebarColumn),
  downBreakpointSidebarColumn: down(v.breakpointSidebarColumn),
}

export default breakpoints
