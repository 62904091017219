import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import v from "../styles/vars"
import breakpoints from "../styles/breakpoints"
import { em, px } from "../styles/units"
import { BrandingPageWide } from "./page-wide"
import Navigation from "./navigation"
import clearfix from "../styles/clearfix"
import { transparentize } from "polished"
import SrOnly from "../utils/SrOnly"
import MenuIcon from "../utils/MenuIcon"

const NavigationMain = styled.nav`
  position: relative;
  font-size: ${em(v.navFontSize / v.fontSize)};
  line-height: 1;
  background-color: ${v.navBackground};

  ${breakpoints.upHorizontalNavigation} {
    box-shadow: inset 0 -5px 2px -2px ${transparentize(1 - 0.25, "#000")};

    .menu-toggle {
      display: none;
    }
  }
`

const MenuButton = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
`

const SiteBranding = styled.div`
  ${clearfix}
  position: relative;
  margin-top: ${px(v.gap)};
  border-radius: ${px(v.softRadius)};
  background-color: #fff;

  ${breakpoints.downHorizontalNavigation} {
    margin-top: 0;
    border-radius: 0;
  }
`

const SiteTitleGroup = styled.div`
  margin: 50px 40px 50px 40px;
`

const SiteTitle = styled.div`
  margin: 0;
  font-family: ${v.siteTitleFont};
  font-size: ${em(34 / v.fontSize)};
  line-height: 1;
  text-shadow: 4px 4px 4px #ccc;
  font-weight: normal;
  text-rendering: optimizeLegibility;

  a {
    display: block;
    color: inherit !important;
  }
`

const SiteDescription = styled.p`
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: ${em(13 / v.fontSize)};
  line-height: ${20 / 13};
  font-weight: 300;
  color: #878787;
  text-shadow: 4px 4px 4px #ccc;
`

const Header = ({ siteTitle, siteDescription, variant, toggleMenu }) => {
  return (
    <header id="masthead" className="site-header" role="banner">
      <NavigationMain
        id="site-navigation"
        className="navigation-main"
        role="navigation"
      >
        <SrOnly as="h1">Menu</SrOnly>

        <MenuButton
          type="button"
          className="menu-toggle"
          aria-label="Open menu"
          aria-owns="site-side-navigation-menu"
          onClick={toggleMenu}
        >
          <MenuIcon />
        </MenuButton>

        <BrandingPageWide id="site-navigation-menu">
          <Navigation variant="responsive" disableMobile={!!toggleMenu} />
        </BrandingPageWide>
      </NavigationMain>

      <BrandingPageWide>
        <SiteBranding className="site-branding">
          {/* @todo Special responsive image using <picture> or componential multi-layer photo */}
          <SiteTitleGroup className="site-title-group">
            <SiteTitle
              as={variant === "home" ? "h1" : "p"}
              className="site-title"
            >
              <Link to="/" rel="home">
                {siteTitle}
              </Link>
            </SiteTitle>
            {siteDescription && (
              <SiteDescription className="site-description">
                {siteDescription}
              </SiteDescription>
            )}
          </SiteTitleGroup>
        </SiteBranding>
      </BrandingPageWide>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  variant: PropTypes.string,
  toggleMenu: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
  variant: "default",
}

export default Header
