import { DateTime } from "luxon"

/**
 * Get a link to a post from the GraphQL remark data
 */
export function getPostLink(post) {
  return getPostLinkFromSlug(post.fields.slug)
}

export function getPostLinkFromSlug(pageSlug) {
  const [, year, month, day, slug] = /^(\d+)-(\d+)-(\d+)-(.+)$/.exec(pageSlug)

  return [
    "",
    String(year).padStart(4, "0"),
    String(month).padStart(2, "0"),
    String(day).padStart(2, "0"),
    slug,
    "",
  ].join("/")
}

/**
 * Format an ISO timestamp as a human readable date string
 */
export function formatPostDate(dateString) {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_FULL)
}
