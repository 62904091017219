import { usePlugin } from "tinacms"
import { RemarkCreatorPlugin } from "gatsby-tinacms-remark"

function createContentCreatorPlugin(type, label) {
  return new RemarkCreatorPlugin({
    label: `Create ${label}`,
    fields: [
      {
        name: "slug",
        label: "Slug",
        description: "The url slug to use",
        component: "text",
      },
    ],
    filename: form => {
      console.log(form)
      return `content/${type}/${form.slug}.md`
    },
  })
}

const CreateGroupPlugin = createContentCreatorPlugin("group", "Group")
const CreateConventionPlugin = createContentCreatorPlugin(
  "convention",
  "Convention"
)
const CreateStorePlugin = createContentCreatorPlugin("store", "Store")
const CreateJapaneseEventPlugin = createContentCreatorPlugin(
  "japanese-event",
  "Japanese event"
)

export function useCreatorPlugins() {
  usePlugin(CreateGroupPlugin)
  usePlugin(CreateConventionPlugin)
  usePlugin(CreateStorePlugin)
  usePlugin(CreateJapaneseEventPlugin)
}
