import styled from "styled-components"
import v from "../styles/vars"
import Menu from "react-burger-menu/lib/menus/slide"

const MobileMenu = styled(Menu)`
  padding-top: ${8 * 2 + 24}px;
  background-color: ${v.navBackground};

  .bm-morph-shape {
    fill: ${v.navBackground};
  }
`

export default MobileMenu
