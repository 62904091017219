import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import v from "../styles/vars"
import { px } from "../styles/units"
import { PrimaryButton } from "./button"
import breakpoints from "../styles/breakpoints"
import header from "../utils/header"

const cardPadding = props =>
  props.variant === "primary" ? v.cardPrimaryPadding : v.cardPadding

export const CardBase = styled.div`
  display: block;
  background-color: #fff;
  border-radius: ${px(v.cardRadius)};
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
  padding: ${props => px(cardPadding(props))};
`
export const CardRoot = styled(CardBase)`
  &[data-variant="facebook"] {
    padding: 0;

    iframe {
      border-radius: ${px(v.softRadius)};
      width: 100%;
    }
  }

  .card-image {
    border-radius: 1px;
  }

  &.card-compact {
    .entry-summary {
      p {
        margin: 0.35em 0;
      }
    }
  }

  & > :last-child,
  & > .wr_text > :last-child {
    margin-bottom: 0 !important;
  }

  &.patreon_widget {
    background-color: #232d32;
    color: #fff;

    p {
      margin-top: 0;
      margin-bottom: 10px;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
`

export const CardImg = styled(Img)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 8px;
`

export const ImageFallback = styled.div`
  border-radius: 4px;
  background-image: radial-gradient(
    circle 465px at -15.1% -25%,
    rgba(17, 130, 193, 1) 0%,
    rgba(67, 166, 238, 1) 49%,
    rgba(126, 203, 244, 1) 90.2%
  );
  margin-bottom: 8px;
`

export const TitleWrapper = styled.div`
  position: relative;
`

export const CardTitle = styled(header(2))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;

  &:only-child {
    margin-bottom: ${px(-(1 - v.lineHeight))} !important;
  }
`

export const CardImgTitle = styled(CardTitle)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 1;
  text-align: left;
  background-color: #000;
  background: linear-gradient(to bottom, transparent, #000);
  text-shadow: 0 0 4px rgba(0, 0, 0, 1);
  color: #fff;
  padding: 16px;
  margin: 0;
`

export const CardButtonGroup = styled.div`
  text-align: right;
  margin: 0;
  margin-top: 20px;
`

export const CardFooter = styled.div`
  background-color: #eee;
  border-bottom-right-radius: ${px(v.cardRadius)};
  border-bottom-left-radius: ${px(v.cardRadius)};

  padding: ${props => px(cardPadding(props))};
  margin-top: ${props => px(cardPadding(props))};
  margin-right: ${props => px(-cardPadding(props))};
  margin-bottom: ${props => px(-cardPadding(props))};
  margin-left: ${props => px(-cardPadding(props))};

  div&:only-of-type {
    margin-top: ${props => px(-cardPadding(props))};
    border-top-right-radius: ${px(v.cardRadius)};
    border-top-left-radius: ${px(v.cardRadius)};
  }
`

export const CardMetaList = styled.ul`
  list-style: none;
  margin: -0.75em 0;
  padding: 0;
  font-size: 0.9em;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > li {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 1;
    margin: 0.75em 0;

    svg {
      margin-right: 5px;
    }
  }

  a,
  a:visited {
    color: #444;
    border-bottom: 1px dotted #444;
    text-decoration: none !important;
  }
`

const Card = ({
  root: Root,
  title,
  imageVariant,
  image,
  imageFallback = false,
  buttonText,
  to,
  children,
  ...props
}) => {
  const img = image ? (
    <CardImg
      fixed={image && image.childImageSharp && image.childImageSharp.fixed}
      fluid={image && image.childImageSharp && image.childImageSharp.fluid}
    />
  ) : (
    imageFallback && <ImageFallback style={imageFallback} />
  )

  let header
  if (imageVariant === "title") {
    header = (
      <TitleWrapper>
        {img}
        {title && <CardImgTitle>{title}</CardImgTitle>}
      </TitleWrapper>
    )
  } else {
    header = (
      <>
        {img}
        {title && <CardTitle>{title}</CardTitle>}
      </>
    )
  }

  if (to) {
    header = <Link to={to}>{header}</Link>
  }

  return (
    <Root {...props}>
      {header}
      {children}
      {buttonText && (
        <CardButtonGroup>
          <PrimaryButton as={to && Link} to={to}>
            {buttonText}
          </PrimaryButton>
        </CardButtonGroup>
      )}
    </Root>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  imageVariant: PropTypes.oneOf(["default", "title"]),
  imageFallback: PropTypes.oneOfType([
    PropTypes.exact(false),
    PropTypes.object,
  ]),
  image: PropTypes.any,
  children: PropTypes.node,
  variant: PropTypes.string,
  root: PropTypes.elementType,
  buttonText: PropTypes.string,
  to: PropTypes.string,
}

Card.defaultProps = {
  root: CardRoot,
}

export const HeadCard = styled(Card)`
  margin-bottom: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    margin-bottom: ${px(v.mobileGap)};
  }
`

export default Card
