import React, { useCallback } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Page } from "react-facebook"

import { noFacebook, enableFacebook } from "../../utils/doNotTrack"
import Widget from "../widget"
import UnlinkedBlock from "../unlinked-block"
import v from "../../styles/vars"
import { math } from "polished"

const VisitLinkText = styled.div`
  text-align: center;
  color: #444;
  text-decoration: none !important;
`

const DntNotice = styled.div`
  margin-top: 20px;
  font-size: ${v.textScale(-1)};

  p {
    margin-bottom: ${math(`${v.textScale(-1)} * 0.65`)};

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const FacebookPageWidget = () => {
  const data = useStaticQuery(graphql`
    query FacebookPageWidget {
      site {
        siteMetadata {
          fbPageUrl
        }
      }
      facebookCover: file(relativePath: { eq: "facebook-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const enableFacebookHandler = useCallback(e => {
    e.preventDefault()
    enableFacebook()
  }, [])

  if (noFacebook) {
    return (
      <Widget>
        <UnlinkedBlock
          as="a"
          href={data.site.siteMetadata.fbPageUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <VisitLinkText>
            Visit <em>Vancouver Anime</em> on Facebook
          </VisitLinkText>
          <Img fluid={data.facebookCover.childImageSharp.fluid} />
        </UnlinkedBlock>

        <DntNotice>
          <p>
            {
              'Our Facebook page widget has been disabled to prevent it tracking you because your browser is configured to send a "Do Not Track" header.'
            }
          </p>
          <p>
            <a href="#enable-facebook" onClick={enableFacebookHandler}>
              Enable Facebook features
            </a>
          </p>
        </DntNotice>
      </Widget>
    )
  }

  return (
    <Widget data-variant="facebook">
      <Page
        href={data.site.siteMetadata.fbPageUrl}
        tabs="timeline"
        showFacepile={false}
        width={340}
        height={500}
        adaptContainerWidth
      />
    </Widget>
  )
}

export default FacebookPageWidget
