import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import Widget from "../widget"
import { getPostLink, formatPostDate } from "../../utils/post-meta"

const RecentPost = styled.li`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`

const RecentPostDate = styled.span`
  display: block;
  color: #aaa;
`

const RecentPostsWidget = () => (
  <StaticQuery
    query={graphql`
      query RecentPosts {
        posts: allMarkdownRemark(
          filter: { fields: { contentKind: { eq: "post" } } }
          sort: { fields: frontmatter___date, order: DESC }
          limit: 5
        ) {
          nodes {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
            }
          }
        }
      }
    `}
    render={data => (
      <Widget title="Recent Posts">
        <ul>
          {data.posts.nodes.map(post => (
            <RecentPost key={post.id}>
              <Link to={getPostLink(post)}>{post.frontmatter.title}</Link>
              <RecentPostDate>
                {formatPostDate(post.frontmatter.date)}
              </RecentPostDate>
            </RecentPost>
          ))}
        </ul>
      </Widget>
    )}
  />
)

export default RecentPostsWidget
