import { mapKeys } from "lodash"
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

export const SeoImageThumbnailFragment = graphql`
  fragment SeoImageThumbnailFragment on File {
    childImageSharp {
      fixed(width: 1200) {
        width
        height
        src
      }
    }
  }
`

function SEO({
  path,
  description,
  lang,
  meta: metaObj,
  keywords,
  title,
  image,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            socialTwitter
            fbAdmins
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  let ogImage
  if (image) {
    const origin = globalHistory.location.origin || site.siteMetadata.siteUrl
    const thumb = image.childImageSharp.fixed

    ogImage = {
      "og:image:url": origin + thumb.src,
      "og:image:secure_url": origin.startsWith("https:")
        ? origin + thumb.src
        : undefined,
      "og:image:type": "image/jpeg",
      "og:image:width": thumb.width,
      "og:image:height": thumb.height,
    }
  }

  const { fb, og, twitter, ...userMeta } = metaObj
  const meta = {
    description: metaDescription,
    keywords: keywords.length > 0 ? keywords.join(", ") : undefined,
    "fb:admins": site.siteMetadata.fbAdmins,
    "og:title": title,
    "og:description": metaDescription,
    "og:type": "website",
    "og:site_name": site.siteMetadata.title,
    "og:url":
      site.siteMetadata.siteUrl + (path || globalHistory.location.pathname),
    ...ogImage,
    "og:locale": "en_CA",
    "twitter:card": "summary",
    "twitter:creator": site.siteMetadata.socialTwitter,
    ...(fb && mapKeys(fb, (value, name) => "fb:" + name)),
    ...(og && mapKeys(og, (value, name) => "og:" + name)),
    ...(twitter && mapKeys(twitter, (value, name) => "twitter:" + name)),
    ...userMeta,
  }

  const metaEntries = []
  for (const [key, value] of Object.entries(meta)) {
    if (value === undefined || value === null) continue

    const keyAttr = /^(fb|og):/.test(key) ? "property" : "name"
    if (Array.isArray(value)) {
      value.forEach(value => {
        metaEntries.push({
          [keyAttr]: key,
          content: value,
        })
      })
    } else {
      metaEntries.push({
        [keyAttr]: key,
        content: value,
      })
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaEntries}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: {},
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.object,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  image: PropTypes.any,
}

export default SEO
