/* globals navigator, location, localStorage */
const doNotTrack =
  typeof navigator !== "undefined" ? !!navigator.doNotTrack : null

function getOverridableDoNotTrack(overrideName) {
  if (doNotTrack != "1") return false
  if (localStorage.getItem(`dnt-override-${overrideName}`) === "true")
    return false

  return true
}

function overrideDoNotTrack(overrideName) {
  localStorage.setItem(`dnt-override-${overrideName}`, "true")

  location.reload()
}

export const noFacebook = getOverridableDoNotTrack("facebook")
export const enableFacebook = () => overrideDoNotTrack("facebook")
