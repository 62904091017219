import { Link } from "gatsby"
import styled from "styled-components"

/**
 * Turn an <a> into a block element with no link styles
 */
const UnlinkedBlock = styled(Link)`
  display: block;
  color: inherit !important;
  text-decoration: none !important;
`

export default UnlinkedBlock
