import { modularScale } from "polished"

const v = {}
export default v

// Settings
v.backgroundColor = "#1989c1"

//
v.softRadius = 2

// Breakpoints
v.breakpointSidebarColumn = 1100
v.breakpointHorizontalNavigation = 780
v.breakpointGridColumns = 700

// Sizes
v.gap = 34
v.mobileGap = 15
v.pagePadding = v.gap
v.pageWidth = 1300 // max-width
v.cardPadding = 20
v.cardPrimaryPadding = 40
v.cardRadius = 2
v.sidebarWidth = 300
v.sidebarAreaWidth = v.sidebarWidth + v.cardPadding * 2
v.sidebarGap = 60

// Text
v.defaultFontSize = 16 // user agent value
v.fontSize = v.defaultFontSize
v.siteTitleFont =
  '"Overlock", "Helvetica Neue", "Helvetica", "Arial", sans-serif'
v.headerFont =
  '"Josefin Sans", "Verdana", "Lucida Sans Unicode", "Lucida Grande", sans-serif'
v.bodyFont =
  '"Open Sans", "Verdana", "Lucida Sans Unicode", "Lucida Grande", sans-serif'
v.fontWeight = 400
v.lineHeight = 1.65

// Text scale
v.modScale = 1.309 // Use a half-step on the 1.618 scale ((1.618 - 1) / 2) + 1;
v.textScale = steps => modularScale(steps, "1em", v.modScale)
v.textZeta = v.textScale(0)
v.textEpsilon = v.textScale(1)
v.textDelta = v.textScale(2)
v.textGamma = v.textScale(3)
v.textBeta = v.textScale(4)
v.textAlpha = v.textScale(5)
v.textMega = v.textScale(6)
v.textGiga = v.textScale(7)

// Navigation
v.navBackground = "#3a3a3a"
v.navText = "#fff"
v.navFontSize = 14
